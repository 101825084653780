@import '~bootstrap/dist/css/bootstrap.min.css';


html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}
body {
    display: flex;
    background: rgb(255, 255, 255);
}
#top-row {
    z-index: 999999;
}
::-webkit-scrollbar {
    display: none;
}
* {
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.pointer {
    cursor: pointer;
}
.grab {
    cursor: grab;
}
.not-allowed {
    cursor: not-allowed;
}
.crosshair {
    cursor: crosshair;
}
.sensorTag {
    pointer-events: none;
    height: 150px;
    position: absolute;
    /* margin-left: 100px; */
    /* box-shadow: 0 3px 8px rgb(136, 136, 136); */
}
.sensorTag:hover {
    cursor: pointer;
}
.moduleContainer {
    height: 700px;
}
.allModules {
    height: 700px;
}
#redLight {
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: redLight;
}
@keyframes liveLight {
    from {
        opacity: 1;
      }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes redLight {
    from {
        opacity: 1;
      }
    10% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@font-face {
    font-family: "Bolder";
    src: local("Bolder"), url(./assets/fonts/Bolder.ttf) format("truetype");
    font-weight: normal;
}
@font-face {
    font-family: "Bolder";
    src: local("Bolder_Italic"), url(./assets/fonts/Bolder_Italic.ttf) format("truetype");
    font-style: italic;
}
@font-face {
    font-family: "Bolder";
    src: local("Bolder_Light"), url(./assets/fonts/Bolder_Light.ttf) format("truetype");
    font-weight: lighter;
}
@font-face {
    font-family: "Bolder";
    src: local("Bolder_Bold"), url(./assets/fonts/Bolder_Bold.ttf) format("truetype");
    font-weight: bold;
}
.moduleContainer-small {
    width: 1px !important;
    height: 1px !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* google disable test since it is underlapping menu and looks silly  */
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}